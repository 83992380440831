<template>
  <div class="c-judge-editor">
    <div class="c-judge-editor__values">
      <table v-if="group.rosterLines === 'subElements'"
             class="c-judge-editor__scores-table c-judge-editor__scores-table--sub-elements">
        <tr v-if="group.multiple">
          <td class="c-judge-editor__scores-table-header"><strong>{{ $t('score.' + group.element) }}</strong></td>

          <td class="value-label">
            {{$t('score.value')}}
          </td>
        </tr>
        <tr v-for="element in group.elements" :key="element.element">
          <td class="c-judge-editor__scores-table-header">
            <span class="c-judge-editor__scores-title">{{$t('score.' + element.element)}}</span>
          </td>
          <td class="c-judge-editor__scores-table-value"
              v-for="value in getElementValues(element)" :key="value.order">
            <scoreValue :value="value" :editing="currentIndex === value.order"
                        :editInput="currentInput" @selectValue="selectValue($event)"/>
          </td>
        </tr>
      </table>

      <table v-else-if="group.rosterLines === 'separate'" class="c-judge-editor__scores-table">
        <tr v-if="group.multiple">
          <td>&nbsp;</td>

          <td class="value-label">
            {{$t('score.value')}}
          </td>
          <td v-if="group.judgeTotal.enabled"
              class="value-label">
            {{$t('score.total')}}
          </td>
        </tr>
        <tr v-for="(element, e_index) in group.elements" :key="element.element">
          <td class="c-judge-editor__scores-table-header">
            <span class="c-judge-editor__scores-title">{{$t('score.' + element.element)}}</span>
          </td>
          <td class="c-judge-editor__scores-table-value"
              v-for="value in getElementValues(element)" :key="value.order">
            <scoreValue :value="value" :editing="currentIndex === value.order"
                        :editInput="currentInput" @selectValue="selectValue($event)"/>
          </td>
          <td class="c-judge-editor__scores-table-group"
              v-if="group.judgeTotal.enabled && e_index === 0">
            <scoreValue :value="getJudgeTotalValue(group)" :editing="false"/>
          </td>
        </tr>
      </table>

      <table v-else class="c-judge-editor__scores-table">
        <tr v-if="group.multiple">
          <td>&nbsp;</td>
          <template v-for="(element) in group.elements">
            <td v-for="value in getElementValues(element)" :key="value.order"
                class="value-label">
              {{element.multiple ? (element.countLabels ? element.countLabels[value.properties.index]
              : $t('score.'+element.element).substr(0,1).toUpperCase() + (value.properties.index+1))
              : $t('score.'+element.element).substr(0,3).toUpperCase()}}
            </td>
          </template>
          <td v-if="group.judgeTotal.enabled" class="value-label">
            TOT
          </td>
        </tr>
        <tr>
          <td class="c-judge-editor__scores-table-header">
            <span class="c-judge-editor__scores-title">{{$t('score.' + group.group)}}</span>
          </td>
          <template v-for="element in group.elements">
            <td v-for="value in getElementValues(element)" :key="value.order"
                class="c-judge-editor__scores-table-value">
              <scoreValue :value="value" :editing="currentIndex === value.order"
                          :editInput="currentInput" @selectValue="selectValue($event)"/>
            </td>
          </template>
          <td v-if="group.judgeTotal.enabled">
            <scoreValue :value="getJudgeTotalValue(group)" :editing="false"/>
          </td>
        </tr>
      </table>

    </div>

    <div class="c-judge-editor__numpad" v-if="currentIndex >= 0">
      <component :is="values[currentIndex].valueType.inputType"
                 @value="valuePressed($event)"
                 @action="actionPressed($event)"
                 :valueType="values[currentIndex].valueType"
                 :properties="values[currentIndex].properties"/>
    </div>

    <div class="c-actions">
      <div class="c-actions__buttons">
        <button v-on:click.prevent="previous()"
                class="c-button c-button--primary"
                :class="{'c-button--disabled': ! hasPrevious()}">
          &lt; {{$t('panel.previous')}}
        </button>
      </div>

      <div class="c-actions__buttons">
        <button v-on:click.prevent="cancel()" v-if="chair"
                class="c-button c-button--secondary">
          {{$t('cancel')}}
        </button>
        <button v-on:click.prevent="next()"
                class="c-button c-button--primary"
                :class="{'c-button--disabled': ! complete && ! currentInput}">
          {{$t('panel.next')}} >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import clone from 'lodash/clone';
  import concat from 'lodash/concat';
  import filter from 'lodash/filter';
  import findIndex from 'lodash/findIndex';
  import forEach from 'lodash/forEach';

  const scoreHandler = require('lib/scoreHandler.js');

  import {round, ceil, pow} from 'mathjs';
  import numeral from 'numeral';


  export default {
    name: "edit-scores",
    data: function () {
      return {
        group: {},
        values: [],
        currentIndex: -1,
        currentInput: '',
        complete: false,
      }
    },
    components: {
      'scoreValue': require('client/components/models/scoreValue.vue').default,
      'numpad': require('../common/numpad.vue').default,
      'select-numeric': require('../common/select-numeric.vue').default,
      'select-options': require('../common/select-options.vue').default,
    },
    computed: {
      configuration: function () {
        return this.$store.state.exercise.exerciseConfiguration;
      },
      activePass: function () {
        return this.$route.params.pass;
      },
      exercise: function () {
        return this.$store.state.exercise.exercise;
      },
      chair: function () {
        return this.$store.state.exercise.chair;
      },
      pages: function () {
        return this.$store.state.exercise.pages;
      },
      currentPage: function () {
        return this.$store.state.exercise.currentPage;
      },
      inputFinished: function () {
        return this.$store.state.exercise.inputFinished;
      }
    },
    created: function () {
      this.initPage();

      document.onkeyup = (evt) => {
        this.keyPressed(evt);
      };

      this.selectFirstValue();
    },
    watch: {
      inputFinished: function () {
        if (this.inputFinished) {
          this.currentIndex = -1;
        } else if (this.currentIndex === -1) {
          this.selectFirstValue();
        }
      },
      exercise: function () {
        this.setComplete();
      },
      pages: function () {
        this.setComplete();
      },
      currentPage: function () {
        this.initPage();
      },
    },
    methods: {
      initPage: function () {
        this.setGroup();
        this.setComplete();
      },
      setGroup: function () {
        this.group = this.pages[this.currentPage];

        let values = [];
        forEach(this.group.elements, element => {
          values = concat(values, element.values);
        });
        this.values = values;
      },
      getJudgeTotalValue: function (group) {
        return {
          properties: {
            leg: parseInt(this.activePass),
            group: group.group,
            element: 'total',
            index: -1,
            position: group.position,
          },

          valueType: group.judgeTotal.valueType
        };
      },
      getElementValues: function (element) {
        return filter(element.values, value => {
          return value.enabled;
        });
      },
      setComplete: function () {
        let complete = true;
        forEach(this.values, (item, index) => {
          const scoreValue = scoreHandler.exerciseGetValue(this.exercise, item.properties);
          if (!item.enabled) return;

          if (scoreValue === undefined) {
            if (index !== this.currentIndex) {
              complete = false;
            } else {
              if (!this.currentInput.length > 0) {
                complete = false;
              }
            }
          }
        });
        this.complete = complete;
      },

      /** Select the first unconfirmed value on the form */
      selectFirstValue: function () {
        if (this.inputFinished) {
          this.currentElementIndex = -1;
          this.currentValueIndex = -1;
          return;
        }

        forEach(this.values, (value, index) => {
          const scoreValue = scoreHandler.exerciseGetValue(this.exercise, value.properties);
          if (!scoreValue || !scoreValue.confirmed) {
            this.currentIndex = index;
            return false;
          }
        });
      },

      selectNextValue: function () {
        // select next input value, if on last set selection idle.

        this.saveCurrentInput();

        let currentIndex = this.currentIndex;

        const cont = true;
        while (cont) {
          if (++currentIndex >= this.values.length) {
            currentIndex = -1;
            break;
          }
          if (! this.values[currentIndex].enabled) {
            continue
          }

          const scoreValue = scoreHandler.exerciseGetValue(this.exercise, this.values[currentIndex].properties);
          if (!scoreValue || !scoreValue.confirmed) {
            this.currentIndex = currentIndex;
            break;
          }
        }
      },

      valuePressed: function (event) {
        const value = this.values[this.currentIndex];
        const newInput = this.currentInput + event.value;
        const val = numeral(newInput).value();
        let max = value.valueType.max;
        const key = value.properties.group + '.' + value.properties.element + '.max';
        if (this.configuration[key]) {
          max = this.configuration[key];
        }
        if (val > max + 0.0000001) {
          return;
        }
        let dotIndex = newInput.indexOf('.');
        if (dotIndex >= 0) {
          if (newInput.length - ++dotIndex > ceil(value.valueType.precision)) {
            return;
          }
        }
        this.currentInput = newInput;
      },

      next: function () {
        this.saveCurrentInput();
        this.currentIndex = 0;
        this.$store.dispatch("exercise.nextPage", {});
      },
      cancel: function () {
        this.$store.dispatch("exercise.cancel", {nopanel: false});
      },
      previous: function () {
        this.saveCurrentInput();
        this.currentIndex = 0;
        this.$store.dispatch("exercise.previousPage", {});
      },
      hasPrevious: function () {
        const prevIndex = findIndex(this.pages, (page, index) => {
          return index < this.currentPage && !page.confirmed;
        });
        return this.currentPage > 0 && prevIndex >= 0;
      },
      saveCurrentInput: function () {

        if (this.currentIndex >= 0 && this.currentIndex < this.values.length && this.currentInput.length > 0) {
          let value = 0
          console.log(this.currentInput)
          if (this.currentInput === '-') {
            value = -1
          }
          else {
            value = numeral(this.currentInput).value()
            const valueType = this.values[this.currentIndex].valueType
            const factor = pow(10, valueType.precision)
            value = round(factor * value) / factor
          }

          let opts = clone(this.values[this.currentIndex].properties)
          opts.value = value
          opts.exerciseId = this.exercise.id

          console.log(opts)
          this.$store.dispatch('exercise.setScoreValue', opts)
          this.currentInput = ''
        }
      },
      selectValue: function (event) {
        const value = event.value;
        if (value.order !== this.currentIndex) {
          this.saveCurrentInput();
          this.currentIndex = value.order;
        }
      },
      keyPressed: function (event) {
        if (event.keyCode === 27) {
          this.currentInput = '';
          return false;
        }

        if (event.keyCode === 9) { // tab
          if (event.shiftKey) {
            //this.previous();
          } else {
            this.selectNextValue();
          }
          return false;
        }

        if (event.keyCode === 13) { // enter
          this.next();
          return false;
        }

        if (this.currentIndex >= 0 && this.currentIndex < this.values.length) {
          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
            this.valuePressed({value: event.key});
          } else if (event.keyCode === 8) {
            this.actionPressed({action: 'back'});
          } else if (event.keyCode === 110 || event.keyCode === 190) {
            this.actionPressed({action: 'dot'});
          }
          return false;
        }
      },
      actionPressed: function (event) {
        switch (event.action) {
          case 'dot':
            if (this.currentInput.indexOf('.') >= 0) {
              return
            }
            this.currentInput += '.'
            break
          case 'skip':
            this.currentInput = '-'
            this.selectNextValue()
            break
          case 'next':
            this.selectNextValue()
            break
          case 'back':
            this.currentInput = this.currentInput.substr(0, this.currentInput.length - 1);
            break
        }
      },
    }
  };
</script>

<style scoped>
  .value-label {
    text-align: center;
  }
</style>
